<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.edit_m", {
                                m: $t("message.branch"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div
                    class="timeline-items__right rounded-sm w-100 p-4"
                    v-loading="loadingData"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                >
                    <el-form
                        ref="form"
                        status-icon
                        :model="form"
                        :rules="rules"
                    >
                        <el-row :gutter="20">
                            <el-col :span="24">
                              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{ $t("message.name") }}
                              </span>
                                <el-form-item
                                    prop="name"
                                    class="label_mini"
                                >
                                    <crm-input
                                        :size="'medium'"
                                        :inputValue="form.name"
                                        v-model="form.name"
                                        autocomplete="off"
                                        :placeholder="$t('message.name')"
                                        :class="mode ? 'input__day' : 'input__night'"
                                    ></crm-input>
                                </el-form-item>
                            </el-col>
                            <!-- end col -->
                            <!-- <el-col :span="24">
                                <span class="input--label d-block mb-2">
                                    {{ $t("message.branch_parental") }}
                                </span>
                                <el-form-item prop="branch_id">
                                    <select-branch
                                        :id="form.branch_id"
                                        :size="'medium'"
                                        :placeholder="columns.branch_id.title"
                                        v-model="form.branch_id"
                                    ></select-branch>
                                </el-form-item>
                            </el-col> -->
                            <!-- end col -->

                            <el-col :span="24">
                                <span class="input--label d-block mb-2" :class="mode ? 'text__day2' : 'text__night2'">
                                    <span class="start_top">*</span>
                                    {{ $t("message.company") }}
                                </span>
                                <el-form-item prop="company_id">
                                    <select-company
                                        :id="form.company_id"
                                        :size="'medium'"
                                        :placeholder="columns.company_id.title"
                                        v-model="form.company_id"
                                    ></select-company>
                                </el-form-item>
                            </el-col>
                            <!-- end col -->
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectBranch from "@/components/filters/inventory/select-branch";
import selectCompany from "@/components/filters/inventory/select-company";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer, show],
    name: "BranchController",
    components: {
        selectBranch,
        selectCompany,
    },
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "branch/rules",
            model: "branch/model",
            columns: "branch/columns",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            update: "branch/update",
            show: "branch/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
